import React, { useState, useEffect } from "react";
import logo from "./../../assets/client/New_Unipride_Logo.png";
import "./header.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isOpaque, setIsOpaque] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [uniformDropdownOpen, setUniformDropdownOpen] = useState(false);

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    setIsOpaque(isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle = {
    backgroundColor: isOpaque
      ? "rgba(255, 255, 255, 1)"
      : "rgba(255, 255, 255, 1)",
    transition: "background-color 0.5s ease",
  };

  const linksStyle = {
    color: isOpaque ? "black" : "black",
  };

  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("uniform");
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL +
            `categories/${categoryName}/subcategories`
        );
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setProducts(data);
          } else {
            console.log(data.message);
          }
        } else {
          throw new Error("Product not found");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [categoryName]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  const handleSubCategory = (category) => {
    setSelected(category);
    navigate(`/uniformCategory/${category}`);
    setUniformDropdownOpen(false);
  };

  const closeUniformDropdown = () => {
    setUniformDropdownOpen(false);
    document.getElementById("unipridemenu").classList.remove("show");
  };

  return (
    <>
      <div className="header">
        <div
          className="main-container navbar navbar-expand-lg"
          style={headerStyle}
        >
          <div className="row container-fluid">
            <div className="col-md-2 col-4 navbar-brand p-0">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
            <button
              className="navbar-toggler col-4"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#unipridemenu"
              aria-controls="unipridemenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={linksStyle}
            >
              <i className="bi bi-list"></i>
            </button>
            <div
              className="col-10 collapse navbar-collapse menu-bar p-0 h-100"
              id="unipridemenu"
            >
              <ul className="navbar-nav navbar-nav-scroll">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      closeUniformDropdown();
                    }}
                    style={linksStyle}
                  >
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/about"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      closeUniformDropdown();
                    }}
                    style={linksStyle}
                  >
                    About
                  </Link>
                </li>
                <li
                  className="nav-item"
                  onMouseLeave={() => setUniformDropdownOpen(false)}
                >
                  <div className="dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      style={linksStyle}
                      // onClick={() => {
                      //   setUniformDropdownOpen(!uniformDropdownOpen);
                      // }}
                      onMouseEnter={() => setUniformDropdownOpen(true)} // Show on hover
                    >
                      Uniforms
                    </Link>
                    <ul
                      className={`dropdown-menu cursor-pointer ${
                        uniformDropdownOpen ? "show" : ""
                      }`}
                    >
                      {products?.map((item, index) => {
                        return (
                          <li key={index} className="dropdown-list">
                            <a
                              className="dropdown-item cursor-pointer"
                              onClick={() => {
                                handleSubCategory(item?.name);
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                                closeUniformDropdown();
                              }}
                            >
                              {item?.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
                {/* Other menu items */}
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    to="/customisedTshirt"
                    style={linksStyle}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Design Your Tshirt
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    to="/CustomMerchandise"
                    style={linksStyle}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Custom Merchandise
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    to="/contact"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    style={linksStyle}
                  >
                    Contact Us
                  </Link>
                </li>
                {isLogin === true ? (
                  <li class="nav-item">
                    <Link
                      class="nav-link"
                      to="/dashboard"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      style={linksStyle}
                    >
                      Dashboard
                    </Link>
                  </li>
                ) : (
                  console.log("Dashboard")
                )}
                <hr />
                <li class="nav-item iconsnumbers">
                  <div className="iconsAndNumber row">
                    <div className="icons-outer  col-12">
                      <div className="icons-inner">
                        <Link to="mailto:sales@unipride.in">
                          <span className="">
                            <i class="bi bi-envelope"></i>{" "}
                          </span>
                          sales@unipride.in
                        </Link>
                      </div>
                    </div>

                    <div className="icons-number col-12">
                      <Link to="tel:+918815190040">
                        <i class="bi bi-telephone"></i>
                        +91 88151 90040
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
