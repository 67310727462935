import React, { useState, useEffect } from "react";
import "./singleProduct.scss";
import Header from "../../component/header/header";
import people from "../../assets/human.png";
import Footer from "../../component/footer/footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  maxHeight: "80vh",
};

const SingleProduct = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [radioValue, setRadioValue] = useState("");
  const [product, setProduct] = useState([]);

  const [inputHandler, setInputHandler] = useState({
    name: "",
    number: "",
    productName: "",
    message: "",
  });

  const onChangeInputHandler = (event) => {
    setInputHandler({
      ...inputHandler,
      [event.target.name]: event.target.value,
    });
    console.log(event.target.value);
  };
  const { id } = useParams();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + `getProductById/${id}`
        );
        if (response.ok) {
          const data = await response.json();
          setProduct(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [id]);

  // console.log("aaaa", product);
  const submitHandler = async (event) => {
    // e.preventDefault();

    // const { name, number, productName, message } = inputHandler;

    const { data } = await axios.post(
      "https://unipride-website-9xdk.onrender.com/productContact/createContact",
      {
        name: inputHandler.name,
        number: inputHandler.number,
        productName: product?.title,
        message: inputHandler.message,
      }
    );
    if (data.success) {
      handleClose();
      toast.success("Response Send Successfully", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      setInputHandler({
        ...inputHandler,
        name: "",
        number: "",
        productName: "",
        message: "",
      });
    } else {
      console.log("error");
      handleClose();
      toast.error("Wrong Information", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      setInputHandler({
        ...inputHandler,
        name: "",
        number: "",
        productName: "",
        message: "",
      });
    }
  };

  return (
    <>
      <div className="singleProduct-main">
        <div className="singleProduct-hero-img-section">
          <Header />

          <div
            className="singleProduct-main-hero-content-section"
            id="head"
          ></div>
        </div>

        <div className="singleProduct-hero-image">
          <div className="singleProduct-hero-image-carousel row">
            {/* <OwlCarousel
                  className="owl-theme"
                  loop
                  key={product?.length}
                  margin={10}
                  nav={false}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 1,
                    },
                    1000: {
                      items: 1,
                    },
                  }}
                >
                  <div>
                    <div className="p-2">
                      <img src={product?.frontImage} alt="" />
                    </div>
                  </div>

                  <div>
                    <div className="p-2">
                      <img src={product?.backImage} alt="" />
                    </div>
                  </div>
                </OwlCarousel> */}
            <div className="section-1 col-md-6">
              <div className="outer">
                <div className="inner">
                  <div className="singleProduct-section-1">
                    <div className="section-1-heading">
                      <h1>{product?.title}</h1>
                    </div>
                    <div className="section-1-text">
                      <p>{product?.description}</p>
                    </div>
                  </div>
                  <div className="singleProduct-section-2 ">
                    <div className="singleProduct-section-2-inner">
                      <div className="section-2-heading">
                        <h5>Product Information</h5>
                      </div>
                      <div className="section-2-subheading">
                        <h6>CATEGORY</h6>
                        <p>{product?.categoryName}</p>
                      </div>
                      <div className="section-2-subheading">
                        <h6>SUBCATEGORY</h6>
                        <p>{product?.subCategoryName}</p>
                      </div>
                      <div className="section-2-button">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          data-bs-whatever="@mdo"
                        >
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-2 col-md-6">
              <div className="outer">
                <div className="inner">
                  <div className="owl-outerr">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      key={product?.length}
                      margin={10}
                      nav
                      responsive={{
                        0: {
                          items: 1,
                        },
                        600: {
                          items: 1,
                        },
                        1000: {
                          items: 1,
                        },
                      }}
                    >
                      <div>
                        <div className="img-outer">
                          <div className="img-inner">
                            <img src={product?.frontImage} alt="" />
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="img-outer">
                          <div className="img-inner">
                            <img src={product?.backImage} alt="" />
                          </div>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="singleProduct-mid-section">
          <div className="singleProduct-mid-section-outer row">
            <div className="singleProduct-section-1 col-md-9">
              <div className="section-1-heading">
                <h1>{product?.title}</h1>
              </div>
              <div className="section-1-text">
                <p>{product?.description}</p>
              </div>
            </div>
            <div className="singleProduct-section-2 col-md-3">
              <div className="singleProduct-section-2-inner">
                <div className="section-2-heading">
                  <h5>Product Information</h5>
                </div>
                <div className="section-2-subheading">
                  <h6>CATEGORY</h6>
                  <p>{product?.categoryName}</p>
                </div>
                <div className="section-2-subheading">
                  <h6>SUBCATEGORY</h6>
                  <p>{product?.subCategoryName}</p>
                </div>
                <div className="section-2-button">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    data-bs-whatever="@mdo"
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="modal-outer">
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Contact Us
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="mb-3">
                      <label for="recipient-name" class="col-form-label">
                        Name:
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={inputHandler.name}
                        onChange={onChangeInputHandler}
                      />
                    </div>
                    <div class="mb-3">
                      <label for="recipient-name" class="col-form-label">
                        Number:
                      </label>
                      <input
                        class="form-control"
                        type="number"
                        name="number"
                        placeholder="Number"
                        value={inputHandler.number}
                        onChange={onChangeInputHandler}
                        step="any"
                      />
                    </div>

                    <div class="mb-3">
                      <label for="message-text" class="col-form-label">
                        Message:
                      </label>
                      <textarea
                        class="form-control"
                        type="text"
                        name="message"
                        placeholder="Message"
                        value={inputHandler.message}
                        onChange={onChangeInputHandler}
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    onClick={submitHandler}
                    class="btn btn-primary"
                  >
                    Send message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Footer />
      </div>
    </>
  );
};

export default SingleProduct;
