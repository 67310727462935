import React, { useEffect, useState } from "react";
import "./login.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigation = useNavigate();
  const [inputHandler, setInputHandler] = useState({
    email: "",
    password: "",
  });
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      navigation("/dashboard");
    } else {
      navigation("/login");
    }
  }, []);

  const handleInputChange = (e) => {
    setInputHandler({
      ...inputHandler,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputHandler);
    if (inputHandler.email === "") {
      alert("Please enter your email");
    } else if (inputHandler.password === "") {
      alert("Please enter your password");
    } else {
      const data = await axios
        .post(process.env.REACT_APP_BASE_URL_auth + "login", {
          email: inputHandler.email,
          password: inputHandler.password,
        })
        .then((res) => {
          console.log(res.data);
          alert("user login sucessfully");
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          setInputHandler({
            email: "",
            password: "",
          });
          navigation("/dashboard");
        })
        .catch((err) => {
          console.log(err.response.data.message);
          alert(err.response.data.message);
        });
    }
  };
  return (
    <div className="login">
      <Header />
      <div className="login-outer w-100">
        <div className="login-inner">
          <div className="login-form">
            <div className="login-text">
              <h1>Login</h1>
            </div>
            <div className="inputs">
              <div className="login-name">
                <label>
                  <h6>Email</h6>
                </label>
                <input
                  type="email"
                  name="email"
                  value={inputHandler.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                />
              </div>
              <div className="login-password">
                <label>
                  <h6>Password</h6>
                </label>
                <input
                  type="Password"
                  name="password"
                  value={inputHandler.password}
                  onChange={handleInputChange}
                  placeholder="Enter your Password"
                />
              </div>
            </div>
            <div className="login-button-outer">
              <div className="login-button" onClick={handleSubmit}>
                <h4>Login</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
