import { React, useState, useEffect } from "react";
import "./footer.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import logowhite from "./../../assets/client/New_Unipride_Logo.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [isLogin, setIsLogin] = useState(false);
  const homePageHandler = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("uniform");
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState("");
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL +
            `categories/${categoryName}/subcategories`
        );
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setProducts(data);
          } else {
            console.log(data.message);
          }
        } else {
          throw new Error("Product not found");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [categoryName]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  const handleSubCategory = (category) => {
    setSelected(category);
    navigate(`/uniformCategory/${category}`);
  };

  return (
    <>
      <div className="footer w-100">
        <div className="row w-85">
          <div className="col-md-4 col-12 copyright">
            <div className="logo" onClick={homePageHandler}>
              <img src={logowhite} alt="" />
            </div>
            <div className="details">
              <p>
                <Link to="tel:+918815190040">
                  <span className="p-1">
                    <i class="bi bi-telephone"></i>
                  </span>
                  +91 88151 90040
                </Link>
              </p>
              <p>
                <span className="p-1">
                  <i class="bi bi-house-door"></i>{" "}
                </span>
                309/1/2 Gayatri Nagar Tejaji Chowk, Palda, Indore 452010
              </p>
              <p>
                <Link to="mailto:sales@unipride.in">
                  <span className="p-1">
                    <i class="bi bi-envelope"></i>{" "}
                  </span>
                  sales@unipride.in
                </Link>
              </p>
            </div>

            <div className="social">
              <Link to="https://www.instagram.com/uniprideworkwear/">
                <i class="bi bi-instagram"></i>
              </Link>

              <Link to="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFTG05Jpv2N-gAAAY6dvzo4HHbj-2Op_-Ml7STg3ZaraFxl4ZrduqmjBr1j4ron3zQstT3lFCPZ4FqFi-m7zVUctQEFCJ01uTDdnxoak7_DwcvkxrYv1UTiPj2G3cfJ52jihG4=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funipride%2F">
                <i class="bi bi-linkedin"></i>
              </Link>
              <Link to="https://www.facebook.com/profile.php?viewas=100000686899395&id=61557394060704">
                <i class="bi bi-facebook"></i>
              </Link>
            </div>
          </div>
          <div className="col-md-8 col-12 row footer-menu">
            <div className="col-sm-5 company">
              <div className="background-div">
                <h5>Company</h5>
                <ul>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={scrollToTop}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={scrollToTop}>
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/CustomMerchandise" onClick={scrollToTop}>
                      Custom Merchandise
                    </Link>
                  </li>
                  <li>
                    <Link to="/resource-center" onClick={scrollToTop}>
                      Resource Center
                    </Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy" onClick={scrollToTop}>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-7 shop">
              <div className="background-div">
                <h5>Product</h5>
                <ul className="row">
                  {products?.map((item, index) => {
                    return (
                      <li key={index} className="category-list col-sm-6">
                        <a
                          class="category-item cursor-pointer"
                          onClick={() => {
                            handleSubCategory(item?.name);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {item?.name}
                        </a>
                      </li>
                    );
                  })}

                  {/* <li className="category-list col-sm-6">
                    <Link to="/cooperateGifting" onClick={scrollToTop}>
                      Coorporate Gifting
                    </Link>
                  </li> */}

                  <li className="category-list col-sm-6">
                    <Link to="/customisedTshirt" onClick={scrollToTop}>
                      Get your customised Tshirt
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bottomcopyright col-12">
            <div className="d">
              <p>Copyright © 2024 by Unipride. All Rights reserved</p>
              <p className="powered">
                Powered by :{" "}
                <Link to="https://www.ehawkers.co.in/">
                  e-Hawkers Marketing LLP, Indore
                </Link>
              </p>
            </div>
            <p>Made in India</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
