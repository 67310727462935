import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../component/header/header";
import "./EditProduct.scss";
import Footer from "../../component/footer/footer";

const EditProduct = () => {
  const { id } = useParams();
  const [backImg, setBackImg] = useState([]);
  const [tags, setTags] = useState([]);
  const [frontImg, setFrontImg] = useState([]);
  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  const addFrontImg = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setFrontImg([...frontImg, event.target.value]);
      event.target.value = "";
    }
  };
  const addBackImg = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setBackImg([...backImg, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const [inputHandler, setInputHandler] = useState({
    title: " ",
    shortDescription: " ",
    description: " ",
    categoryName: "",
    subCategoryName: "",
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + `getProductById/${id}`)
      .then((res) => {
        console.log(res.data.data);
        setInputHandler({
          ...inputHandler,
          title: res.data.data.title,
          shortDescription: res.data.data.shortDescription,
          description: res.data.data.description,
          categoryName: res.data.data.categoryName,
          subCategoryName: res.data.data.subCategoryName,
          tags: res.data.data.tags || [],
          frontImage: res.data.data.frontImage,
          backImage: res.data.data.backImage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const history = useNavigate();

  const backToDashBoard = () => {
    history("/dashboard");
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "getCategory"
        );
        console.log(response.data);
        setCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.name === e.target.value
    );
    setSubcategories(selectedCategory.subcategories);
    setInputHandler({ ...inputHandler, categoryName: e.target.value });
  };

  const handleSubCategoryChange = (e) => {
    setInputHandler({ ...inputHandler, subCategoryName: e.target.value });
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
    } else {
      history("/login");
    }
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const {
      title,
      shortDescription,
      description,
      categoryName,
      subCategoryName,
    } = inputHandler;
    if (title === " ") {
      alert("Add Title");
    } else if (shortDescription === " ") {
      alert("Add short description");
    } else if (description === " ") {
      alert("Add description");
    } else {
      let frontImageUrl, backImageUrl;
      if (frontImg) {
        const formData = new FormData();
        formData.append("image", frontImg);
        const imageResponse = await axios.post(
          process.env.REACT_APP_BASE_URL + "upload",
          formData
        );
        if (imageResponse.data.success) {
          frontImageUrl = imageResponse.data.url;
        }
      }
      if (backImg) {
        const backFormData = new FormData();
        backFormData.append("backImage", backImg);
        const backImageResponse = await axios.post(
          process.env.REACT_APP_BASE_URL + "BackImageUpload",
          backFormData
        );
        if (backImageResponse.data.success) {
          backImageUrl = backImageResponse.data.url;
        }
      }

      // Check if the user has provided new tags. If not, keep the existing ones.
      const newTags = tags.length > 0 ? tags : inputHandler.tags;

      const { data } = await axios.put(
        process.env.REACT_APP_BASE_URL + `updateProduct/${id}`,
        {
          title: title,
          shortDescription: shortDescription,
          description: description,
          categoryName: categoryName,
          subCategoryName: subCategoryName,
          frontImage: frontImageUrl,
          backImage: backImageUrl,
          colors: newTags, // Use the newTags variable here
        }
      );
      if (data.success) {
        alert("Product Updated Successfully");
        setInputHandler({
          ...inputHandler,
          title: "",
          shortDescription: "",
          description: "",
        });
        history("/dashboard"); // Assuming 'history' is from 'react-router-dom'
      }
    }
  };

  console.log(inputHandler);

  return (
    <>
      <Header />
      <div className="editProduct">
        <section>
          {
            <div className="form_data">
              <div className="cross" onClick={backToDashBoard}>
                <div className="cross-inner">
                  <i class="bi bi-arrow-left"></i>
                </div>
                <h4>Back</h4>
              </div>
              <div className="form_heading">
                <h1>Edit Product</h1>
              </div>

              <form>
                <div className="form_input">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.title}
                    id="title"
                    name="title"
                    placeholder="Title"
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="shortDescription">Short description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={3}
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.shortDescription}
                    id="shortDescription"
                    name="shortDescription"
                    placeholder=" A short Description ...."
                  />
                </div>

                <div className="form_input">
                  <label htmlFor="description">Description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={10}
                    type="description"
                    onChange={onChangeInputHandler}
                    value={inputHandler.description}
                    id="description"
                    name="description"
                    placeholder="description... "
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="categoryName">Category Name</label>
                  <select
                    className="categoryName"
                    id="categoryName"
                    name="categoryName"
                    value={inputHandler.categoryName}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select a category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_input">
                  <label htmlFor="subCategoryName">Sub Category Name</label>
                  <select
                    className="categoryName"
                    id="subCategoryName"
                    name="subCategoryName"
                    value={inputHandler.subCategoryName}
                    onChange={handleSubCategoryChange}
                  >
                    <option value="">Select a subcategory</option>
                    {subcategories.map((subcategory, index) => (
                      <option key={index} value={subcategory.name}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_input">
                  <label for="productImage">Front Image</label>

                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      onKeyDown={(event) => addFrontImg(event)}
                      onChange={(e) => setFrontImg(e.target.files[0])}
                      name="image"
                      className="tags-input w-100 p-2"
                      multiple
                      required
                    />
                  </div>
                </div>
                <div className="form_input">
                  <label for="productImage">Back Image</label>

                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      type="file"
                      id="backImage"
                      accept="image/*"
                      onKeyDown={(event) => addBackImg(event)}
                      onChange={(e) => setBackImg(e.target.files[0])}
                      name="backImage"
                      className="tags-input w-100 p-2"
                      required
                    />
                  </div>
                </div>
                <div className="tags">
                  <label>Colors</label>
                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      onKeyDown={(event) => addTags(event)}
                      type="text"
                      className="tags-input w-100 p-2"
                      placeholder="Please enter your tags here"
                    />
                    {tags.map((tag, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{tag}</span>
                        <span
                          className="close"
                          onClick={() => removeTags(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="btn" onClick={onSubmitHandler}>
                  Update Product
                </div>
              </form>
            </div>
          }
        </section>
      </div>
      <Footer />
    </>
  );
};

export default EditProduct;
