import React, { useState, useEffect } from "react";
import "./home.scss";
// import Testimonial from "../../component/testimonial/testimonial";
import { Link, useNavigate } from "react-router-dom";
import GiftingCard from "../../component/giftingCard/giftingCard";
import { Helmet } from "react-helmet-async";
// import HomeCarousel from "../../component/HomeCarousel/HomeCarousel";
import HomeHeroImage from "../../assets/image 6.png";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import jQuery from "jquery";
// home hero
// import Appron from "../../assets/Appron (2).png";
import Industrial from "../../assets/Industrial (2).png";
import School from "../../assets/School.png";
import Doctor from "../../assets/Doctor (1).png";
// tshirt no image and sport no contant
import Sport from "../../assets/sport.png";
import Hoddies from "../../assets/Hoddies (1).png";
import Petrol from "../../assets/PetrolPump.png";

// import Chef from "../../assets/Chef (1).png";
// import Patient from "../../assets/Patient (1).png";

//USP Icons
import inhouse from "./../../assets/icon/In-House Production.png";
import iterations from "./../../assets/icon/iteration.png";
import vision from "./../../assets/icon/Your vision, Our expertise.png";
import decade from "./../../assets/icon/Decade of Experience.png";
import customerCentric from "./../../assets/icon/Customer-Centric Approach.png";
import premiummaterial from "./../../assets/icon/Quality Materials.png";

//client Logos
import ageco from "./../../assets/client/Ageco.png";
import cipla from "./../../assets/client/cipla.svg";
import intas from "./../../assets/client/intas.png";
import lupin from "./../../assets/client/lupin.png";
import mosiac from "./../../assets/client/Mosiac.png";
import shivganga from "./../../assets/client/Shivganga.png";
import sunpharma from "./../../assets/client/sunpharma.png";
import vone from "./../../assets/client/V_One.webp";

//Fiber partners
import arvind from "./../../assets/fabric/Arvind.svg";
import adarsh from "./../../assets/fabric/adarsh.gif";
import mofatlal from "./../../assets/fabric/Mafatlal.png";
import raymond from "./../../assets/fabric/raymond.png";
import skumar from "./../../assets/fabric/s-kumars.jpg";
import sangam from "./../../assets/fabric/sangam.png";
import subhlakshmi from "./../../assets/fabric/shubhlaxmi.svg";
import valji from "./../../assets/fabric/Valji.png";

// import HomeCarouselCard from "../../component/HomeCarouselCard/HomeCarouselCard";
import homeicon1 from "../../assets/homricon1.png";

import homeicon2 from "../../assets/homeicon2.png";
import homeicon3 from "../../assets/homeicon3.png";
// testimonial images
import testimonial1 from "../../assets/testimonial/testimonial1.jpg";
import testimonial2 from "../../assets/testimonial/testimonial2.jpg";
import testimonial3 from "../../assets/testimonial/testimonial3.jpg";
import testimonial4 from "../../assets/testimonial/testimonial4.jpg";
import testimonial5 from "../../assets/testimonial/testimonial5.jpg";
import testimonial6 from "../../assets/testimonial/testimonial6.jpg";
import testimonial1comma from "../../assets/testimonial/testimonial1comma.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Home = () => {
  const [categoryName, setCategoryName] = useState("Coorporate Gifting");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL +
            `products/subcategory/Industrial Uniforms`
        );
        if (response.ok) {
          const data = await response.json();
          setProducts(data.products);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [categoryName]);

  console.log(products);

  const changePage = () => {
    navigate("/customisedTshirt");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const images = [
    raymond,
    adarsh,
    arvind,
    mofatlal,
    skumar,
    sangam,
    subhlakshmi,
    valji,
  ];

  const settings = {
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const setting = {
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: true,
    cssEase: "linear",
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
    rtl: true,
  };
  const [inputHandler, setInputHandler] = useState({
    name: "",
    email: "",
    number: "",
    message: "Get a callback",
  });
  const onChangeInputHandler = (event) => {
    console.log(event.target.value);
    setInputHandler({
      ...inputHandler,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = () => {
    const data = axios
      .post(process.env.REACT_APP_BASE_URL + `createContact`, inputHandler)
      .then((response) => {
        console.log(response);
        toast.success("Response Send Successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        setInputHandler({
          name: "-",
          email: "-",
          number: inputHandler.number,
          message: "Get a callback",
        });
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(data);
  };

  return (
    <div className="homeMain">
      <Helmet>
        <title>Quality T Shirts For Men | Best T Shirt Websites</title>
        <meta
          name="description"
          content="At Unipride, we're obsessed with perfection. We carefully choose the best fabrics and focus on every little aspect to ensure top-notch quality."
        />
        <meta
          name="keywords"
          content="best t shirt company, quality tee shirts mens, best t shirt websites, best t shirt sites, best quality tee shirts for printing, good t shirt websites, work wear t shirts"
        />
      </Helmet>
      <Header />
      <div className="homeHero">
        <div className="homeHeroMain">
          <div className="row w-100 align-items-center">
            <div className="right-carousel">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                autoplay="true"
                mouseDrag={false}
                touchDrag={false}
                nav={false}
                autoplayTimeout={5000}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 1,
                  },
                  1000: {
                    items: 1,
                  },
                }}
              >
                <div class="hero-carousel-item">
                  <div className="row carousel-side">
                    <div className="col-md-6 text-left">
                      <h2>
                        Gear Up <br />
                        for Safety
                      </h2>
                      <div className="readmore">
                        <Link to="/uniformCategory/Industrial%20Uniforms">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class=" d-flex justify-content-center align-items-center image-card-hero">
                        <img src={Industrial} class="" alt="Industrial" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hero-carousel-item">
                  <div className="row carousel-side">
                    <div className="col-md-6 text-left">
                      <h2>
                        School Style <br />
                        Comfort
                      </h2>
                      <div className="readmore">
                        <Link to="/uniformCategory/School%20Uniform">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class=" d-flex justify-content-center align-items-center image-card-hero">
                        <img src={School} class="" alt="School" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hero-carousel-item">
                  <div className="row carousel-side">
                    <div className="col-md-6 text-left">
                      <h2>
                        Comfort
                        <br />
                        Meets Care
                      </h2>
                      <div className="readmore">
                        <Link to="/uniformCategory/Hospital%20Linens%20&%20Linens">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class=" d-flex justify-content-center align-items-center image-card-hero">
                        <img src={Doctor} class="" alt="Doctor" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hero-carousel-item">
                  <div className="row carousel-side">
                    <div className="col-md-6 text-left">
                      <h2>
                        School Style <br />
                        Statements
                      </h2>
                      <div className="readmore">
                        <Link to="/uniformCategory/School%20Uniforms">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class=" d-flex justify-content-center align-items-center image-card-hero">
                        <img src={Hoddies} class="" alt="Hoodies" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hero-carousel-item">
                  <div className="row carousel-side">
                    <div className="col-md-6 text-left">
                      <h2>
                        Gear Up <br /> for Work
                      </h2>
                      <div className="readmore">
                        <Link to="/uniformCategory/Petrol%20Pump%20Uniform">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class=" d-flex justify-content-center align-items-center image-card-hero">
                        <img src={Petrol} class="" alt="Petrol" />
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <div className="usp">
        <div className="key-points d-flex justify-content-center row">
          <div className="key-card key-card-1 col-lg-3 col-md-6">
            <div className="gapping">
              <div className="card-image">
                <img src={vision} alt="Vision" />
              </div>
              <div className="card-text">
                <h6>Your vision, Our expertise</h6>
                <p>
                  We craft tailored solutions, simplifying your journey from
                  idea to reality.
                </p>
              </div>
            </div>
          </div>
          <div className="key-card key-card-2 col-lg-3 col-md-6">
            <div className="gapping">
              <div className="card-image">
                <img src={iterations} alt="Iteration Unipride Unlimited" />
              </div>
              <div className="card-text">
                <h6>Unlimited Iterations</h6>
                <p>
                  Providing endless design revisions until it reflects your
                  unique identity flawlessly.
                </p>
              </div>
            </div>
          </div>
          <div className="key-card key-card-3 col-lg-3 col-md-6">
            <div className="gapping">
              <div className="card-image">
                <img src={inhouse} alt="in-house production" />
              </div>
              <div className="card-text">
                <h6>In-House Production</h6>
                <p>
                  Our in-house efforts ensure meticulous attention to detail and
                  swift, reliable delivery.
                </p>
              </div>
            </div>
          </div>
          <div className="key-card key-card-4 col-lg-3 col-md-6">
            <div className="gapping">
              <div className="card-image">
                <img src={customerCentric} alt="Customer Centric Approach" />
              </div>
              <div className="card-text">
                <h6>Customer-Centric Approach</h6>
                <p>
                  We exceed expectations with personalized care and service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homeGifting">
        <div className="homeGiftingSub">
          <div className="giftingText">
            <h2>Industrial Uniform</h2>
          </div>
          <div className="giftingCard">
            <OwlCarousel
              key={products?.length} // Add this line
              className="owl-theme"
              loop
              margin={10}
              // autoplay="true"
              nav={false}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              {products?.map((item, index) => {
                return (
                  <div key={index}>
                    <GiftingCard item={item} index={index} />
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className="our-clients">
        <div className="our-clients-sub row">
          <div className="heading col-md-3">
            <h2>Globally Cherished</h2>
            <h6>Teams Worldwide Love Us</h6>
          </div>
          <div className="logo-carousel col-md-9">
            <div id="gallery" className="scrollc" style={{ margin: "1rem" }}>
              <Slider {...settings} className="owl-theme">
                <div className="h-100">
                  <div className="item p-2">
                    <img src={ageco} alt=" Ageco Client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={cipla} alt=" Cipla Client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={intas} alt=" intas client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={lupin} alt="lupin client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={mosiac} alt="mosiac client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={shivganga} alt="shivganga client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={sunpharma} alt="sunpharma client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={vone} alt="v-one client" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="call-back">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 heading">
              <h4>Talk to the Uniform Experts</h4>
            </div>
            <div className="col-sm-6 callback-form">
              <div className="form">
                <input
                  type="tel"
                  name="number"
                  value={inputHandler.number}
                  onChange={onChangeInputHandler}
                  placeholder="Number"
                />
                <button onClick={submitHandler}>Get a Call Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-fabric-partner">
        <div className="our-fabric-sub row">
          <div className="logo-carousel col-md-9">
            <div id="gallery" className="scrollc" style={{ margin: "1rem" }}>
              <Slider {...setting} className="owl-theme">
                <div className="h-100">
                  <div className="item p-2">
                    <img src={raymond} alt="Raymond fabric partner" />
                  </div>
                </div>

                <div className="h-100">
                  <div className="item p-2">
                    <img src={adarsh} alt="adarsh fabric partner" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={arvind} alt="arvind fabric partner" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={mofatlal} alt="mofatlal fabric partner" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={skumar} alt="skumar fabric partner" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={sangam} alt="sangam fabric partner" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={subhlakshmi} alt="subhlakshmi fabric partner" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={valji} alt="valji fabric partner" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="heading col-md-3">
            <h2>Fabric Partners</h2>
            <h6>Exclusively From the Finest</h6>
          </div>
        </div>
      </div>
      <div className="WhyUs">
        <div className="whyUsText">
          <h2>Why Us</h2>
        </div>
        <div className="whyUsContainer">
          <div className="whyUs-section1 row">
            <div className="col-md-4">
              <div className="whyus-card">
                <div className="icon-border">
                  <div className="icon">
                    <img src={homeicon1} alt=" Crafting perfection" />{" "}
                  </div>
                </div>
                <div className="heading">
                  <h4>
                    <span>Crafting</span> Perfection
                  </h4>
                </div>
                <div className="description">
                  <p>
                    At Unipride, we're obsessed with perfection. Our process is
                    all about attention to detail, from the first sketch to the
                    final stitch. We carefully choose the best fabrics and focus
                    on every little aspect to ensure top-notch quality. When you
                    choose us, you're choosing workwear that goes above and
                    beyond, reflecting our passion for excellence and making you
                    stand out.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="whyus-card">
                <div className="icon-border">
                  <div className="icon">
                    <img src={homeicon2} alt="Every Stitch COunts" />{" "}
                  </div>
                </div>
                <div className="heading">
                  <h4>
                    <span>Every</span> Stitch Counts
                  </h4>
                </div>
                <div className="description">
                  <p>
                    We believe in doing things right, down to the last stitch.
                    At Unipride, our process is all about getting every detail
                    spot on. We sweat the small stuff—selecting the finest
                    materials and making sure every stitch is perfect. It's this
                    dedication that ensures your uniforms are not just good, but
                    outstanding. With Unipride, expect nothing less than
                    perfection.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="whyus-card">
                <div className="icon-border">
                  <div className="icon">
                    <img src={homeicon3} alt=" Quality you can trust" />
                  </div>
                </div>
                <div className="heading">
                  <h4>
                    <span>Quality You </span> Can Trust
                  </h4>
                </div>
                <div className="description">
                  <p>
                    Unipride means quality you can count on. From start to
                    finish, we're dedicated to making sure your uniforms are
                    nothing short of excellent. Our commitment to perfection
                    shines through in every piece we create. When you choose
                    Unipride, you're choosing top-notch quality that speaks
                    volumes about your professionalism.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial">
        <div className="container-main">
          <div className="heading">
            <h2>Testimonial</h2>
          </div>
          <div className="key-points d-flex justify-content-center">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              autoplay="false"
              autoplayHoverPause={true}
              autoplayTimeout={100000000}
              responsive={{
                0: {
                  items: 1,
                },
                678: {
                  items: 2,
                },
                900: {
                  items: 3,
                },
                1200: {
                  items: 4,
                },
              }}
            >
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img
                        src={testimonial1comma}
                        alt=" testimonial Unipride"
                      />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        As a tech startup, we sought workwear reflecting our
                        innovative spirit while maintaining cohesive branding.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial4} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Rishika Kothari</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        As a construction company, having durable and branded
                        workwear is crucial for our team's safety and
                        professionalism.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial2} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Samriddhi Acholiya</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        In our eyes, their attention to detail and commitment to
                        delivering exactly what we envisioned truly set them
                        apart.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial1} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Anmol Naik</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        It was great to see quality fabric being offered at such
                        reasonable prices compared to other brands and
                        e-commerce websites.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial6} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Aarohi Kardekar</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        As a hospitality business, maintaining a polished and
                        branded appearance is essential for our brand image.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial5} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Ayushi Yadav</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        Unipride's customization and branding options set us
                        apart and leave a lasting impression on clients.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial3} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Khushi Yadav</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="container-main d-flex align-items-center row">
          <div className="text col-md-9">
            <h1>Get Your Customized T-Shirt</h1>
            <p>
              Rejuvenate your workforce’s look with Unipride Workwears. With
              Endless customizations and Branding options, proudly showcase your
              company to make a lasting impression
            </p>
          </div>
          <div className="button col-md-3" onClick={changePage}>
            {/* <h1 className="learn-more">Get your T-shirt</h1> */}
            <h1>
              {" "}
              <Link className="learn-more">Get your T-shirt</Link>{" "}
            </h1>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Footer />
    </div>
  );
};

export default Home;
